import React from 'react';
import Button from '../components/buttons/button';
import { Route } from '../models/site-links';

const AppPage = () => {
  return (
    <div className="min-h-screen flex flex-row items-center justify-center">
      <div>
        <h2 className="text-5xl text-center text-grey-dark font-hairline font-heading">
          Patronscan App
        </h2>
        <Button
          buttonId="ScannerDownloadLink"
          link={Route.AppDownloadLink}
          internal={false}
          className="mx-auto w-full"
          blue
          text="Download Patronscan Mobile"
        />
      </div>
    </div>
  );
};

export default AppPage;
